import React from 'react';

import { Avatar } from '../components/Avatar/Avatar';
import { Layout, Pagination } from '../components/common';
import { MetaData } from '../components/common/meta';
import Author from '../images/Author';
import Posts from '../images/Posts';

import style from '../styles/pages/authors.scss';

import cn from 'classnames';
import { graphql, Link } from 'gatsby';

interface IAuthorProps {
  data: {
    allGhostAuthor: any;
  };
  location: {
    pathname: string;
  };
  pageContext: any;
}

/**
 * Authors page (/authors)
 *
 *
 */
const Authors = ({ data, location, pageContext }: IAuthorProps) => {
  const authors = data.allGhostAuthor.edges;

  return (
    <>
      <MetaData
        data={data}
        location={location}
        title="Personal Finance Bloggers Who Write for Think Save Retire"
        description="Who has written for Think Save Retire (other than Steve Adcock)? Many experts in the personal finances/FIRE movement space have shared their stories with us."
      />
      <Layout headerClassName={style.header} mainClass={style.main}>
        <div className={style.hero}>
          <div className="container">
            <h1>Meet our voices</h1>
            <h4>
              <Author /> {data.allGhostAuthor.totalCount} authors
            </h4>
            <p>
              Think Save Retire&apos;s authors and featured writers bring a variety of backgrounds
              with money and a wide spectrum of perspectives. Interested in writing for us?
              We&apos;re always looking to <Link to="/write-partner/">grow our community</Link>
            </p>
          </div>
        </div>
        <div className={style.authorsPage}>
          <div className="container">
            <div className="row">
              {authors.map((author: any, i: number) => (
                <div key={i} className={cn('col-12 col-sm-6 col-md-4', style.authorCard)}>
                  <div>
                    <Avatar profile_image={author.node.profile_image} name={author.node.name} />
                    <h3 className={style.postName}>
                      <Link to={`/author/${author.node.slug}/`}>
                        {author.node.name.split('@')[0]}
                      </Link>
                    </h3>
                    <Link
                      to={`/author/${author.node.slug}/`}
                      className={cn(style.postsCount, {
                        [style.noBio]: !author.node.bio,
                      })}
                    >
                      <Posts />
                      {author.node.postCount} {author.node.postCount === 1 ? 'post' : 'posts'}
                    </Link>
                    <p
                      className={cn(style.postBio, {
                        [style.noBio]: !author.node.bio,
                      })}
                      dangerouslySetInnerHTML={{ __html: author.node.bio ?? '* * *' }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <Pagination className={style.pagination} pageContext={pageContext} />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Authors;

export const pageQuery = graphql`
  query($limit: Int!, $skip: Int!) {
    allGhostAuthor(sort: { order: DESC, fields: [postCount] }, limit: $limit, skip: $skip) {
      totalCount
      edges {
        node {
          ...GhostAuthorFields
          postCount
        }
      }
    }
  }
`;
