import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const AuthorIcon = (props: IIconProps) => {
  const { height = 24, width = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.5"
        cx="12.5"
        cy="6"
        r="4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 20.4C4.5 18.1598 4.5 17.0397 4.93597 16.184C5.31947 15.4314 5.93139 14.8195 6.68404 14.436C7.53968 14 8.65979 14 10.9 14H14.1C16.3402 14 17.4603 14 18.316 14.436C19.0686 14.8195 19.6805 15.4314 20.064 16.184C20.5 17.0397 20.5 18.1598 20.5 20.4V22H4.5V20.4Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default React.memo(AuthorIcon);
